/*********************************************************************************

	Template Name: Trydo React Degital Agency Template
	Note: This is style css.

**********************************************************************************/

/**************************************************************

	STYLESHEET INDEXING
	|
	|
	|___ Default Styles
	|	|
	|	|___ variables
	|	|___ mixins
	|	|___ reset
	|	|___ typography
	|	|___ extend
	|	|___ animations
	|	|___ shortcode
	|	|___ spacing
	|
	|
	|___Header Styles
	|	|___ header
	|	|___ mainmenu
	|
	|
	|___Inner Styles
	|	|___ breadcrump
	|	|___ section-title
	|	|___ button
	|	|___ pagination
	|	|___ socialicon
	|	|___ footer
	|
	|___Elements Styles
	|	|___ portfolio
	|	|___ service
	|	|___ slider
	|	|___ counterup
	|	|___ testimonial
	|	|___ brand
	|	|___ team
	|	|___ callto-action
	|	|___ tab
	|	|___ accordion
	|	|___ list
	|	|___ contact
	|
	|___BLog Styles
	|	|___ blog
	|	|___ blog-details
	|
	|___Template Styles
	|	|___ about
	|	|___ portfolio-details
	|	|___ service-details
	|	|___ preview
	|
	|
	|___ END STYLESHEET INDEXING

***************************************************************/


/**************************************
    Default Styles
***************************************/
@import 'default/variables';
@import 'default/mixins';
@import 'default/reset';
@import 'default/typography';
@import 'default/extend';
@import 'default/animations';
@import 'default/shortcode';
@import 'default/spacing';



/**************************************
    Header Styles
***************************************/

@import 'header/header';
@import 'header/mainmenu';


/**************************************
    Inner Styles
***************************************/

@import 'common/breadcrump';
@import 'common/section-title';
@import 'common/button';
@import 'common/pagination';
@import 'common/socialicon';
@import 'common/footer';



/**************************************
    Elements Styles
***************************************/

@import 'elements/portfolio';
@import 'elements/service';
@import 'elements/slider';
@import 'elements/counterup';
@import 'elements/testimonial';
@import 'elements/brand';
@import 'elements/team';
@import 'elements/callto-action';
@import 'elements/tab';
@import 'elements/accordion';
@import 'elements/list';
@import 'elements/contact';
@import 'elements/progress';
@import 'elements/column';
@import 'elements/404';
@import 'elements/pricing';



/**************************************
    Blog Styles
***************************************/

@import 'blog/blog';
@import 'blog/blog-details';



/**************************************
    Template Styles
***************************************/

@import 'template/about';
@import 'template/portfolio-details';
@import 'template/service-details';
@import 'template/dark-version';
@import 'template/preview';

.nodisplayer{display: none !important;}
.displayer{display: flex !important;}
.displaynone, #displaynone{visibility: hidden;}
.displaynone2, #displaynone2{display: none;}
.opacity0{opacity: 0.1; transition:all .3s;}
.opacity1{opacity: 1;  transition:all .3s;}
.zindex5{z-index: 100}
.vid{z-index: 1000;}
.mt-50{margin-top: 50px;}
.meduza{font-size: 10px; margin-top: 5px;}
.pricedetails{padding: 0;}
.flex-column{flex-direction: column;}
.img-rounded-6{border-radius: 10px;}
.d-sm-none{@media #{$md-layout} { display: none;}@media #{$sm-layout} {display: none;}}
.lotdescr label{@media #{$sm-layout} {margin-left: 8px;}}
.phoneheader a{    margin-top: 15px;  }
.bottomed{color: #fff; transform: translateX(0); position: absolute; transition: all .3s;}
.calculator{margin-top: 200px;}
.call-to-action{display: none;
  .old_price{
    background: #000;
  }
}
.true {display: block !important;}
.Telegram .icon{color:#0088cc !important;}
.Viber .icon{color:#7360f2 !important;}
.Telegram:hover .icon,.Viber:hover .icon{color:#fff !important;}
.Telegram.Telegram::before{ background: #0088cc !important; background-image: none !important;}
.Viber.Viber::before{ background: #7360f2 !important; background-image: none !important;}
.Telegram:hover .bottomed {transition: all .3s; transform: translate(10px, -10px); padding-bottom: 20px;}
.Viber:hover .bottomed {transition: all .3s; transform: translate(10px, -10px); padding-bottom: 20px;}
.frame-holder{
border: 1px solid rgb(201, 0, 1); overflow: hidden; margin: 15px auto; width: 100%;
}
.frame{border: 0px none; margin-top: -80px; height: 700px; width: 110%;}
.countdown{font-size: 1rem !important;}
.bold{font-weight:800 !important; font-family: 'Montserrat',sans-serif; }
.slidebutton{margin-top: 30px;}
