/*===================
    Section Title 
====================*/

.section-title {
    margin-top: 60px;
    h2{
        &.title{
            font-size: 60px;
            margin-bottom: 8px;
            
            @media #{$lg-layout} {
                font-size: 50px;
            }
            @media #{$sm-layout} {
                font-size: 36px;
                margin-bottom: 7px;
            }
        }
    }
    p {
        font-size: 18px;
        line-height: 30px;
        color: rgba(29, 29, 36, 0.75);
        a {
            color: rgba(29, 29, 36, 1);
            font-weight: 500;
            &:hover {
                color: $theme-color;
            }
        }
        br {
            @media #{$sm-layout} {
                display: none;    
            }
        }
    }

    h3 {
        font-size: 36px;
        line-height: 26px;
        
        @media #{$sm-layout} {
            font-size: 26px;
            margin-bottom: 8px;
        }
    }
    .service-btn{
        margin-top: 36px;
        @media #{$sm-layout} {
            margin-top: 12px;
        }
    }

    &.service-style--2 {
        h2 ,
        h3 {
            color: #ffffff;
        }
        p{
            padding: 0 21%;
            font-weight: 300;
            color: #ffffff;
            @media #{$md-layout} {
                padding: 0 7%;
            }
            @media #{$sm-layout} {
                padding: 0;
            }
        }
    }

    &.service-style--3{
        h2 {

        }
        p {
            padding: 0 21%;
            font-weight: 300;
            color: #717173;
            @media #{$md-layout} {
                padding: 0 7%;
            }
            @media #{$sm-layout} {
                padding: 0;
            }
        }
        &.text-left {
            p {
                padding: 0 3% 0 0;
            }
        }
    }

}
.section-title-default {
    h2 {
        &.title {
            line-height: 1.34;
            font-size: 48px;
            @extend %fontWeight400;
        }
    }
}


.section-title {
    h2{
        @media #{$sm-layout} {
            font-size: 36px;
            margin-bottom: 7px;
        }
    }
}











